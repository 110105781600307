<template>
  <div>
    <van-nav-bar
        v-if="!isInMini()"
        id="nav-bar"
        title="请选择要存放的区域"
        left-text="返回"
        left-arrow
        @click-left="$router.back()"
    />
    <van-cell v-for="area in areaList" :key="area.id" :title="area.areaName" is-link @click="clickArea(area)"/>
  </div>
</template>

<script>
import Client from "@/api";
export default {
  name: "AreaList",
  data: function () {
    return {
      areaList: []
    }
  },
  mounted() {
    this.loadArea()
    localStorage.removeItem('orderId')
  },
  methods: {
    loadArea() {
      Client.get('userArea').then(res => {
        if (res.data.state === 'ok') {
          this.areaList = res.data.list
        } else {
          this.$toast.fail(res.data.msg)
        }
      }).catch(err => {
        this.$toast.fail(err.response.data.msg)
      })
    },
    clickArea(area) {
      localStorage.setItem('userAreaId', area.id)
      localStorage.removeItem('orderId')
      this.navTo('/locker')
    }
  }
}
</script>

<style scoped>
/deep/ .van-cell__title {
  font-size: 15px;
  font-weight: bold;
  color: #333333;
}
</style>
